const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
    self.events.change(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function() {
        if ($(this).is('a, button')) {
          var target = $(this).data('proxy')
          var value = $(this).data('value')

          if (Array.isArray(target) && Array.isArray(value)) {
            for (var i = 0; i < target.length; i++) {
              self.service.change(target[i], value[i])
            }
          } else {
            self.service.change(target, value)
          }
        }
      });
    },

    change: function(trigger) {
      $(document).on('change', trigger, function() {
        var target = $(this).data('proxy')
        var value = $(this).val()

        if (typeof $(this).data('value') !== 'undefined') {
          value = $(this).data('value')
        }

        self.service.change(target, value)
      });
    }
  },

  service: {
    change: function (target, value) {
      $(target).val(value)
      $(target).trigger('change')
    }
  }
}

$(".property-search__toggle-btn").on('click', function(){
  $(this).toggleClass("show-filter");
  $('#property-search-filters').slideToggle();
});
