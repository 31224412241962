const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(parent) {
    self.events.click(parent)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function() {
        var tip = $(this).find('.tooltip__content')

        self.service.toggle(tip)
      })
    }
  },

  service: {
    toggle: function(target) {
      $(target).toggleClass('active')
    }
  }
}
