const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    method: 'GET',
    url: ajax.ajaxurl,
    dataType: 'json',
    contentType: false,
    processData: false
  },

  init: function(selector) {
    self.events.click(selector)
  },

  events: {
    click: function(selector) {
      $(document).on('click', selector, function() {
        var target = $(this).data('proxy')
        var data = {
          action: 'fetch'
        }

        data = $.param(data)
        self.service.post(data, target)
      })
    }
  },

  service: {
    post: function(data, target) {
      $.ajax({
        method: self.props.method,
        url: self.props.url,
        data: data,
        dataType: self.props.dataType,
        contentType: self.props.contentType,
        processData: self.props.processData,
        success: function(response) {
          self.service.show(response, target)
        },
        error: function(data) {
          console.log(data)
        }
      })
    },

    show: function(response, target) {
      $(target).html(response)
    }
  }
}
