const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(selector) {
    $(selector).each(function() {
      self.service.initStreet(this)
      self.events.streetShown(this)
    })
  },

  events: {
    streetShown: function(street) {
      $(document).on('streetShown', function() {
        self.service.initStreet(street)
      })
    }
  },

  service: {
    initStreet: function(street) {
      var sv = new google.maps.StreetViewService()
      var panorama = new google.maps.StreetViewPanorama(street)
      var marker = $(street).find('.marker')
      var lat = $(marker).data('lat')
      var lng = $(marker).data('lng')

      var center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }

      sv.getPanorama({
        location: center,
        radius: 500
      }, function(data, status) {
        if (status === 'OK') {
          self.service.showStreet(panorama, data.location.pano)
        }
      })
    },

    showStreet: function(panorama, location) {
      panorama.setPano(location)
      panorama.setPov({
         heading: 270,
         pitch: 0
       })
      panorama.setVisible(true)
    }
  }
}
