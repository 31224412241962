const $ = jQuery.noConflict()
var Cookie = require('../utility/cookie.js')

var self = module.exports = {
  props: {
    cookie: 'cookie-policy',
    header: '#header',
  },

  init: function(trigger) {
    self.events.click(trigger)
    self.events.change(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        if ($(this).is('input[type="checkbox"]') || $(this).is('input[type="radio"]')) {
          return
        }

        if ($(this).is('a, button')) {
          event.preventDefault()
        }

        self.service.handler(this)
      })
    },

    change: function(trigger) {
      $(document).on('change', trigger, function(event) {
        self.service.handler(this)
      })
    }
  },

  service: {
    handler: function(trigger) {
      var target = $(trigger).parent().hasClass('menu-item-has-children') ? $(trigger).siblings('.sub-menu') : $(trigger).data('collapse')
      var action = typeof $(trigger).data('action') !== 'undefined' ? $(trigger).data('action') : 'toggle'
      var group = typeof $(target).data('group') ? $('[data-group="' + $(target).data('group') + '"]') : null
      var triggers = $('[data-collapse="' + target + '"]')

      if (group) {
        self.service.hideGroup(group, target)
      }

      if (target === '#alert-cookie') {
        self.service.setCookie(target)
      }

      if (action == 'show') {
        $(target).addClass('show')
      } else if (action == 'hide') {
        $(target).removeClass('show')
      } else {
        self.service.toggleTarget(target)
      }

      self.service.toggleTriggers(triggers)
    },

    toggleTarget: function(target) {
      if ($(target).hasClass('show')) {
        $(target).removeClass('show')
      } else {
        $(target).addClass('show')
      }

      $('[data-fixed]').css('top', $(self.props.header).height() + 'px')
    },

    toggleTriggers: function(triggers) {
      $(triggers).each(function() {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active')
        } else {
          $(this).addClass('active')
        }
      })
    },

    hideGroup: function(group, target) {
      $(group).not(target).each(function() {
        $(this).removeClass('show')
      })
    },

    setCookie: function(target) {
      var Header = require('../modules/header.js')
      var Cookie = require('../utility/cookie.js')

      Cookie.store(self.props.cookie, 'accepted')
      Header.init('#header')
    }
  }
}
