const $ = jQuery.noConflict()
var Proxy = require('../plugins/proxy.js')

var self = module.exports = {
  init: function(trigger) {
    self.events.change(trigger)
  },

  events: {
    change: function(trigger) {
      $(document).on('change', trigger, function(event) {
        if (typeof $(this).data('switch') !== 'undefined') {
          self.service.switch(this)
        }
      })
    }
  },

  service: {
    switch: function(trigger) {
      var original = $(trigger).val()
      var updated = $(trigger).data('switch')

      $(trigger).val(updated)
      $(trigger).data('switch', original)

      if (typeof $(trigger).data('proxy') !== 'undefined') {
        var target = $(trigger).data('proxy')

        Proxy.service.change(target, updated)
      }
    }
  }
}
