module.exports = {
  get: function(cname) {
    var name = cname + "="
    var ca = document.cookie.split(';')

    for (var i=0; i<ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0)==' ') c = c.substring(1)
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
    }
    return ""
  },
  store: function(cname, cvalue, expires) {
    var d = new Date()
    d.setTime(d.getTime() + (expires))
    document.cookie = cname + "=" + cvalue + ';expires=' + d.toUTCString() + ';path=/'
  }
};
