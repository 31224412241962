const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(parent) {
    self.service.each(parent)
    self.events.resize(parent)
    self.events.scroll(parent)
  },

  events: {
    scroll: function (parent) {
      $(window).on('resize', function() {
        self.service.each(parent)
      })
    },

    resize: function (parent) {
      $(window).on('scroll', function () {
        self.service.each(parent)
      })
    }
  },

  service: {
    each: function (parent) {
      $(parent).each(function() {
        self.service.freeze(this)
      })
    },

    freeze: function (parent) {
      var header = $('#header')
      var target = $(parent).data('freeze')
      var offset = $(parent).data('offset') ? $(parent).data('offset') : 0
      var paddingTop = parseInt($(parent).css('padding-top'))
      var paddingBottom = parseInt($(parent).css('padding-bottom'))
      var windowTop = $(window).scrollTop()
      var parentTop = $(parent).offset().top
      var parentHeight = $(parent).outerHeight()
      var parentBottom = parentTop + parentHeight
      var targetTop = $(target).offset().top
      var targetLeft = $(target).offset().left
      var targetWidth = $(target).outerWidth()
      var targetHeight = $(target).outerHeight()
      var headerHeight = $(header).outerHeight()
      var diffTop = parentTop - (windowTop + headerHeight)
      var bottomMax = parentBottom
      var bottomPos = windowTop + headerHeight + targetHeight + paddingTop + paddingBottom

      if (bottomPos >= bottomMax) {
          $(target).css({
            'position': 'absolute',
            'top': '',
            'bottom': '0',
            'left': offset,
            'width': targetWidth,
            'height': targetHeight
          })
      } else if (diffTop <= 0) {
        $(target).css({
          'position': 'fixed',
          'top': headerHeight + paddingTop,
          'bottom': '',
          'left': targetLeft,
          'width': targetWidth,
          'height': targetHeight
        })
      } else {
        $(target).css({
          'position': '',
          'top': '',
          'bottom': '',
          'left': '',
          'width': '',
          'height': ''
        })
      }

      $(window).on('resize', function() {
        $(target).css({
          'position': '',
          'top': '',
          'bottom': '',
          'left': '',
          'width': '',
          'height': ''
        })
      })

    }
  }
}
