const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    icons: {
      class: '.form-input-validation',
      success: '<span class="form-input-validation fas fa-check-circle text-success"></span>',
      fail: '<span class="form-input-validation fas fa-times-circle text-error"></span>'
    }
  },

  init: function(trigger) {
    self.events.change(trigger)
    self.events.typing(trigger)
  },

  events: {
    change: function(trigger) {
      $(document).on('change', trigger, function(event) {
        self.service.check(this)
      })
    },

    typing: function(trigger) {
      $(trigger).donetyping(function () {
        if ($(this).hasClass('fail')) {
          self.service.check(this)
        }
      })
    }
  },

  service: {
    check: function(field) {
      if (self.service.validate(field) == true) {
        self.service.success(field)
      } else {
        self.service.fail(field)
      }
    },

    validate: function(field) {
      var value = $(field).val()
      var type = $(field).attr('type')
      var name = $(field).attr('name')
      var form = $(field).closest('form').attr('id')
      var formID = '#' + form
      var validation = $(field).data('validate').split(' ')
      var result = true

      for (var i = 0, l = validation.length; i<l; i++) {
        if (typeof validator.rules[validation[i]] !== 'function') {
          continue
        }

        if (typeof type !== 'undefined' && type === 'checkbox') {
          console.log($(formID).find('[name="' + name  + '"]'))
        }

        var validated = validator.rules[validation[i]](value, type, name, formID)

        if (validated === false) {
          result = false
        }
      }

      return result
    },

    success: function(field) {
      $(field).removeClass('fail').addClass('success')

      if ($(field).hasClass('form-input')) {
        var parent = $(field).parent()
        $(parent).find(self.props.icons.class).remove()
        $(parent).prepend(self.props.icons.success)
      }
    },

    fail: function(field) {
      $(field).removeClass('success').addClass('fail')

      if ($(field).hasClass('form-input')) {
        var parent = $(field).parent()
        $(parent).find(self.props.icons.class).remove()
        $(parent).prepend(self.props.icons.fail)
      }
    }
  }
}
