const $ = jQuery.noConflict()
var Slider = require('../modules/slider.slick.js')

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(e) {
        e.preventDefault()
        self.service.handler(this)
      })
    }
  },

  service: {
    handler: function(trigger) {
      var group = $('[data-group="' + $(trigger).data('group') + '"]')
      var target = $(trigger).data('tab')

      self.service.toggleTriggers(group, trigger)
      self.service.togglePanels(target)
    },

    toggleTriggers: function(group, trigger) {
      $(group).not(trigger).each(function() {
        $(this).removeClass('active')
      })

      $(trigger).addClass('active')
    },

    togglePanels: function(target) {
      $(target).addClass('active').siblings().removeClass('active')
      Slider.service.heightReset()
    }
  }
}
