const $ = jQuery.noConflict()
var Validation = require('../plugins/validation.js')

var self = module.exports = {
  props: {
    input: '.form-staged input, .form-staged select, .form-staged textarea',
    next: '.form-staged__next',
    prev: '.form-staged__prev',
    tabs: '.tabs-steps',
    step: '.form-staged__step',
    submit: '.form-staged__submit',
    heading: '.form-staged__heading'
  },

  init: function(form) {
    $(form).each(function() {
      self.service.init(this)
    })

    self.events.nextClick(self.props.next)
    self.events.prevClick(self.props.prev)
    self.events.enterKeydown(self.props.input)
    self.events.submitClick(self.props.submit)
    self.events.submitForm(form)
  },

  events: {
    nextClick: function (trigger) {
      $(document).on('click', trigger, function() {
        var form = $(this).closest('.form-staged')

        self.service.check(this, form)
      })
    },

    prevClick: function (trigger) {
      $(document).on('click', trigger, function() {
        var form = $(this).closest('.form-staged')

        self.service.prev(this, form)
      })
    },

    enterKeydown: function (trigger) {
      $(document).on('keydown', trigger, function(e) {
        var $this = $(this)
        var form = $(this).closest('.form-staged')
        var steps = self.service.getSteps(form)
        var step = $(this).closest('[data-step]').data('step')

        // If key pressed is Enter and this is not the final step
        if($this.is("textarea")){
          if (e.which == 13) {
            e.preventDefault();
              var s = $(this).val();
              $(this).val(s+"\n");
              return false;
          }
        }
        if (e.which == 13 && step !== steps) {
          e.preventDefault()
          self.service.check(this, form)
        }
      })
    },

    submitClick: function (trigger) {
      $(document).on('click', trigger, function(e) {
        var form = $(this).closest('.form-staged')
        e.preventDefault()

        self.service.check(this, form)
      })
    },

    submitForm: function (form) {
      $(document).on('submit', form, function(e) {
        self.service.submit(this)
      })
    }
  },

  service: {
    init: function (form) {
      var first = self.service.getSteps(form)
      var steps = self.service.getSteps(form)

      self.service.updateTabs(form, 1)
      self.service.updateProgress(form, 1)
    },

    check: function (trigger, form) {
      if (self.service.validate(trigger, form)) {
        self.service.next(trigger, form)
      } else {
        self.service.error(trigger)
      }
    },

    next: function (trigger, form) {
      var current = $(trigger).closest('.form-staged__step')
      var number = $(current).data('step')
      var next = number + 1
      var page = $(form).find('[data-step="' + next + '"]')
      var error = $(current).find('.form-staged__error')
      var steps = self.service.getSteps(form)

      $(error).removeClass('active')
      self.service.updateHeading(form, next)
      self.service.updateProgress(form, next)
      self.service.updateTabs(form, next)

      if (number == steps) {
        $(form).trigger('submit')
      } else {
        self.service.updatePage(current, page)
      }
    },

    prev: function (trigger, form) {
      var current = $(trigger).closest('.form-staged__step')
      var number = $(current).data('step')
      var prev = number - 1
      var page = $(form).find('[data-step="' + prev + '"]')

      self.service.updatePage(current, page)
      self.service.updateHeading(form, prev)
      self.service.updateProgress(form, prev)
      self.service.updateTabs(form, prev)
    },

    validate: function (trigger, form) {
      var page = $(trigger).closest('.form-staged__step')
      var fields = $(page).find('[data-validate]')
      var valid = true

      // Loop through inputs and check validation
      $(fields).each(function() {
        if (Validation.service.validate(this) === true) {
          Validation.service.success(this)
        } else {
          valid = false
          Validation.service.fail(this)
        }
      })

      return valid
    },

    getSteps: function (form) {
      // Counts number of stages in form
      var steps = $(form).find(self.props.step).length
      return steps
    },

    updateTabs: function(form, current) {
      var tabs = $(form).find(self.props.tabs)
      var items = $(tabs).find('li')
      var count = 1

      $(items).each(function() {
        if (count <= current) {
          $(this).addClass('active')
        } else {
          $(this).removeClass('active')
        }
        count++
      })
    },

    updateProgress: function (form, current) {
      var steps = self.service.getSteps(form)
      var percent = parseFloat(100 / steps) * current
      percent = percent.toFixed()

      $(form).find('.progress-bar').css('width', percent + '%').html(percent + '%')
    },

    updateHeading: function (form, current) {
      var heading = $(form).find(self.props.heading)
      var update = $(form).find('[data-heading-value]')

      if (current > 1) {
        $(heading).addClass('muted')
      } else {
        $(heading).removeClass('muted')
      }

      if (update.length) {
        var text = $(heading).data('heading-text')
        var value = $(update).val()
        value = value.toLowerCase()
        $(heading).html(text)

        var span = $(heading).find('span')
        $(span).html(value)
      }
    },

    updatePage: function (current, page) {
      var firstInput = $(page).find('.form-input').first()
      var autocomplete = $(firstInput).attr('data-autocomplete')
      var date = $(firstInput).attr('data-date')

      $(current).removeClass('active')
      $(page).addClass('active')

      // If the first input is a date picker or autocomplete, don't select it.
      if (typeof autocomplete !== typeof undefined && autocomplete !== false || typeof date !== typeof undefined && date !== false) {
        return
      } else {
        $(firstInput).focus()
      }
    },

    error: function (trigger) {
      var error = $(trigger).closest('.form-staged__step').find('.form-staged__error')

      $(error).addClass('active')
    },

    reset: function (form) {
      var first = $(form).find('[data-step="1"]')
      var success = $(form).find('.form-staged__success')

      window.setTimeout(function() {
        if ($(form).hasClass('form--submitting') || $(form).hasClass('form--submitted')) {
          self.service.updatePage(success, first)
          self.service.updateProgress(form, 1)
          self.service.updateTabs(form, 1)
        }
      }, 5000)
    },

    submit: function (form) {
      var steps = self.service.getSteps(form)
      var first = $(form).find('[data-step="1"]')
      var last = $(form).find('[data-step="' + steps + '"]')
      var final = steps + 1

      $(last).removeClass('active')
      self.service.updateTabs(form, final)
      // self.service.reset(form)
    }
  }
}
