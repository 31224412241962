const $ = jQuery.noConflict()


var self = module.exports = {
  cookie: 'cookie-policy',

  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        if ($(this).parent().hasClass('menu-item-has-children')) {
          var target = $(this).siblings('.sub-menu')
        } else if ($(this).hasClass('nav__close')) {
          var target = $(this).data('collapse')
        } else {
          var target = $(this).data('collapse')
        }

        if ($(this).is('a, button')) {
            event.preventDefault()
        }
        self.service.toggle(target)
      })
    }
  },

  service: {
    toggle: function(target) {
      var el = $(target).closest('.menu-item-has-children');
      var group = typeof $(target).data('group') ? $('[data-group="' + $(target).data('group') + '"]') : null
      var nav = $(target).hasClass('sub-menu') ? $(target).closest('.nav__burger') : null

      if (group) {
        self.service.hideGroup(group, target)
      }

      if (nav) {
        self.service.hideGroup('.sub-menu', target)
        $(el).siblings().removeClass('show')
        $(el).toggleClass('show')
      }

      console.log(target)
      if ($(target).hasClass('show')) {
        $(target).removeClass('show')

        if (nav) {
          $(nav).removeClass('double')
        }

        if ($(target).hasClass('nav')) {
          $(target).removeClass('double')
          self.service.hideGroup('.sub-menu', target)
        }
      } else {
        $(target).addClass('show')

        if (nav) {
          $(nav).addClass('double')
        }
      }

      if (target === '#alert-cookie') {
        var Header = require('../modules/header.js')
        var COOKIE = require('../utility/cookie.js')

        console.log(Header)

        Header.init('#header')
        COOKIE.store(self.cookie, 'accepted')
      }
    },

    hideGroup: function(group, target) {
      $(group).not(target).each(function() {
        $(this).removeClass('show')
      })
    }
  }
}
