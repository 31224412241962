const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    filters: {
      field: '.property-search__field',
      container: '.property-search__filters'
    },
    toggle: {
      parent: '[data-toggle-parent]'
    }
  },

  init: function(form) {
    self.events.change(form)
    self.events.toggleTrigger(self.props.toggle.parent)

    $(form).each(function() {
      self.service.filter(this, self.props.filters.field)
    })
  },

  events: {
    change: function(form) {
      $(form).on('change', 'input[type=radio], input[type=checkbox], input[type=text], select', function() {
        var parent = $(this)
        var alerts = $(form).data('alerts')
        var name = $(this).attr('name')
        var target = $(alerts).find('[name=' + name + ']')
        var value = $(this).val()

        if ($(this).is(':radio') && $(this).prop('checked')) {
          $(target).each(function() {
            var val = $(this).val()
            if (val == value) {
              $(this).prop('checked', true)
            } else {
              $(this).prop('checked', false)
            }
          })
        } else if ($(this).is(':checkbox')) {
          $(target).each(function() {
            var val = $(this).val()
            if (val == value) {
              if ($(parent).prop('checked')) {
                $(this).prop('checked', true)
              } else {
                $(this).prop('checked', false)
              }
            }
          })
        } else {
          $(target).val(value)
        }
      })
    },

    toggleTrigger: function(parent) {
      $(document).on('change', parent, function(e) {
        var trigger = e.target
        self.service.toggle($(trigger))
      })
    }
  },

  service: {
    filter: function(form, fields) {
      var filter = $(form).find(self.props.filters.container)

      $(fields).each(function() {
        var display = $(this).css('display')

        if (display === 'none') {
          $(filter).find('> .row').append($(this))
          $(this).removeClass('hidden-xl-down hidden-xxl-down')
          $(this).css('display', 'block')
        }
      })
    },

    toggle: function(trigger) {
      var group = $(trigger).data('toggle-parent')
      var match = $(trigger).val()
      var target = '[data-toggle-group="' + group + '"]'

      $(target).each(function() {
        var value = $(this).data('toggle-value')
        if (value === match) {
          $(this).addClass('show')
        } else {
          $(this).removeClass('show')
        }
      })
    }
  }
}
