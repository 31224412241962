const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        var text = $(this).data('text') ? $(this).data('text') : $(this).attr('href')

        event.preventDefault()
        self.service.class(this)
        self.service.copy(text)
      })
    }
  },

  service: {
    class: function(target) {
      $(target).addClass('copied').blur();
    },

    copy: function(text) {
      var temp = $('<input>');
      $('body').append(temp);
      temp.val(text).select();
      document.execCommand('copy');
      temp.remove();
    }
  }
}
