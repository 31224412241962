const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    header: {
      height: 0
    },
    first: {
      identifier: null,
      isHero: false
    }
  },

  init: function(target) {
    self.service.init(target)
    self.events.scroll(target)
    self.events.resize(target)
  },

  events: {
    scroll: function(target) {
      $(window).on('scroll', function() {
        self.service.scrollHandler(target)
      });
    },

    resize: function(target) {
      $(window).on('resize', function() {
        self.service.init(target)
      })
    },
  },

  service: {
    init: function(target) {
      var body = $('body')
      var header = $(body).find(target)
      var main = $(body).find('main')

      self.props.header.height = $(header).outerHeight()
      self.props.first.identifier = $(main).find('section:first-child')
      self.props.first.height = $(self.props.first.identifier).outerHeight()
      self.props.first.isHero = $(self.props.first.identifier).find('.hero-large').length ? true : false

      if (self.props.first.isHero) {
        self.service.addInverseStyles(header)
        self.service.logoNegative(header)
      } else {
        self.service.addHeightAsPadding(self.props.first.identifier, self.props.header.height)
      }
    },

    // Get current position of window
    getWindowPosition: function() {
      var top = $(window).scrollTop()
      return top
    },

    // Check window has scrolled past first module
    checkWindowPosition: function () {
      if (self.service.getWindowPosition() > self.props.header.height) {
        return true
      } else {
        return false
      }
    },

    // Add or remove styles to header depending on window position
    scrollHandler: function(target) {
      if (self.service.checkWindowPosition() && $(target).hasClass('transparent')) {
        self.service.addShadow(target)
        self.service.removeTransparentStyles(target)

        if (self.props.first.isHero) {
          self.service.logoDefault(header)
          self.service.removeInverseStyles(header)
        }
      } else if (!self.service.checkWindowPosition() && !$(target).hasClass('transparent')) {
        self.service.removeShadow(target)
        self.service.addTransparentStyles(target)

        if (self.props.first.isHero) {
          self.service.logoNegative(header)
          self.service.addInverseStyles(header)
        }
      }
    },

    // Swap to
    logoDefault: function (target) {
      var logo = $(target).find('.header__logo img')
      var logoSrc = $(logo).data('src-default')

      $(logo).attr('src', logoSrc)
    },

    // Add or remove styles to header depending on window position
    logoNegative: function (target) {
      var logo = $(target).find('.header__logo img')
      var logoSrc = $(logo).data('src-negative')

      $(logo).attr('src', logoSrc)
    },

    // Add room for the header above the first module
    addHeightAsPadding: function (target, height) {
      $(target).css('padding-top', height + 'px')
    },

    // Add transparent styles to header
    addTransparentStyles: function(target) {
      $(target).addClass('transparent')
      $('.modal__close').removeClass('transparent')
    },

    // Remove transparent styles to header
    removeTransparentStyles: function(target) {
      $(target).removeClass('transparent')
      $('.modal__close').addClass('transparent')
    },

    // Add styles to make header elements white
    addInverseStyles: function(target) {
      $(target).addClass('inverted')
      $('.modal__close').removeClass('inverted')
    },

    // Remove styles to make header elements default
    removeInverseStyles: function(target) {
      $(target).removeClass('inverted')
      $('.modal__close').addClass('inverted')
    },

    // Add styles to make header shadow
    addShadow: function(target) {
      //$(target).addClass('box-shadow-default')
    },

    // Add styles to make header shadow
    removeShadow: function(target) {
      //$(target).removeClass('box-shadow-default')
    }
  }
}
