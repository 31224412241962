const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    options: {
      minDate: 0,
      maxDate: '+4w',
      dateFormat: 'DD, d MM yy'
    },
    
    icon: '[data-date-trigger]',
  },

  init: function(selector) {
    $(selector).each(function() {
      $(this).datepicker(self.props.options)
    })

    self.events.iconClick(self.props.icon)
  },

  events: {
    iconClick: function(trigger) {
      $(document).on('click', trigger, function(e) {
        var input = $(this).siblings('[data-date]')
        $(input).datepicker('show')
      })
    }
  },

  service: {

  }
}
