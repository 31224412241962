const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
    self.events.change(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(e) {
        var target = $(this).data('submit')
        var form = target ? target : $(this).closest('form')

        if ($(this).is('a, button')) {
          self.service.submit(form)
        }
      })
    },

    change: function(trigger) {
      $(document).on('change', trigger, function (e) {
        var target = $(this).data('submit')
        var form = target || $(this).closest('form')
        var inputName = $(this).attr('name')
        var formTarget = $(form).data('target')

        console.log(inputName, formTarget)

        if (inputName === 'location') {
          self.service.updateSort()
        }

        if (formTarget) {
          $(formTarget).html('')
        }

        self.service.submit(form)
      })
    }
  },

  service: {
    submit: function(form) {
      $(form).trigger('submit')
    },
    updateSort: function (form) {
      var sort = $('[name="sort"]')
      $(sort).val('distance')
    }
  }
}
