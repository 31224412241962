var $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    proxy: '[data-proxy]'
  },

  init: function(form) {
    self.events.submitted(form)
    self.events.proxy(self.props.proxy)
  },

  events: {
    proxy: function(trigger) {
      $(document).on('click', trigger, function(event) {
        var target = $(this).data('proxy')
        var value = $(this).data('value')
        self.service.updateField(target, value)
      })
    },

    submitted: function(form) {
      $(form).on('submitted', function(event) {
        var inner = $(this).find('.form__inner')
        var success = $(this).closest('[data-success]')

        if (success.length) {
          self.service.success(success)
        } else {
          self.service.hide(inner)
        }
      })
    }
  },

  service: {
    updateField: function(target, value) {
      $(target).val(value)
    },
    hide: function(target) {
      // $(target).slideUp()

      if (target === '#alert-cookie') {
        $('#header').header()
      }
    },
    success: function(target) {
      var panel = $(target).data('success')
      var success = $(target).find(panel)

      $(success).addClass('active')
    }
  }
}
