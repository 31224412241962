const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        self.service.class(this)
      })
    }
  },

  service: {
    class: function(target) {
      $(target).toggleClass('active').blur();
    },

    copy: function(text) {
      var temp = $('<input>');
      $('body').append(temp);
      temp.val(text).select();
      document.execCommand('copy');
      temp.remove();
    }
  }
}
