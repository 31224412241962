var $ = jQuery.noConflict();
// var __URL__ = window.location;

// Require utilities
require('./utility/objectEntries.js')
require('./utility/requestTimeout.js')
require('./utility/doneTyping.js')
require('./utility/tabbing.js')
var Cookie = require('./utility/cookie.js')
// Require jquery components
var Accordion = require('./components/accordion.js')
var Calculator = require('./components/calculator.js')
var Forms = require('./components/form.js')
var FormsStaged = require('./components/form-staged.js')
var Maps = require('./components/maps.google.js')
var Menu = require('./components/menu.js')
var Modal = require('./components/modal.js')
var Share = require('./components/share.js')
var Streets = require('./components/streetview.google.js')
var Switch = require('./components/switch.js')
// Require jquery modules
var Header = require('./modules/header.js')
var Footer = require('./modules/footer.js')
var Nav = require('./modules/nav.js')
var PropertySearch = require('./modules/property-search.js')
var Reviews = require('./modules/reviews.ajax.js')
var Slider = require('./modules/slider.slick.js')
var STATEMENT = require('./modules/statement.js')
// Require jquery plugins
var Arrive = require('./plugins/arrive.js')
var AutoComplete = require('./plugins/autocomplete.google.js')
var BranchFinder = require('./plugins/branch-finder.ajax.js')
var Collapse = require('./plugins/collapse.js')
var Copy = require('./plugins/copy.js')
var Currency = require('./plugins/currency.js')
var Date = require('./plugins/date.js')
var Duplicate = require('./plugins/duplicate.js')
var Fetch = require('./plugins/fetch.ajax.js')
var Fixed = require('./plugins/fixed.js')
var Freeze = require('./plugins/freeze.js')
//var Height = require('./plugins/height.js')
var Increment = require('./plugins/increment.js')
var Locrating = require('./plugins/locrating.js')
var MatchHeight = require('./plugins/match-height.js')
var MatchRow = require('./plugins/match-row.js')
var Password = require('./plugins/password.js')
var Print = require('./plugins/print.js')
var Proxy = require('./plugins/proxy.js')
var Save = require('./plugins/save.js')
var Search = require('./plugins/search.ajax.js')
var Scroll = require('./plugins/scroll.js')
var Submit = require('./plugins/submit.js')
var Tabs = require('./plugins/tabs.js')
var TextToggle = require('./plugins/text-toggle.js')
var Tooltip = require('./plugins/tooltip.js')
var Validation = require('./plugins/validation.js')
var HorizontalScroll = require('./plugins/horizontal-scroll.js')
var SmoothScroll = require('./plugins/smooth-scroll.js')

// Document ready event
$(function() {
// Modules
 Header.init('#header')
 Footer.init('#footer')
 Nav.init('#nav-primary')
 PropertySearch.init('.property-search')

// Plugins
$('[data-height]').height()
 MatchHeight.init('[data-match-height]')
 MatchRow.init('[data-match-row]')
// Initialisers
// Arrive.init()

  // Components
 Accordion.init('.accordion')
 Calculator.init('.calculator')
 Forms.init('.form')
 FormsStaged.init('.form-staged')
 Maps.init('.map')
 Menu.init('.active')
 Modal.init('.modal')
 Share.init('.btn-share')
 Slider.init('.slick')
 Streets.init('.street')
 Tooltip.init('.tooltip')
})

// Window load event
$(window).on('load', function() {
   // Plugins
  Collapse.init('[data-collapse]')
  Copy.init('[data-copy]')
  Currency.init('[data-currency]')
  Duplicate.init('[data-duplicate]')
  Fixed.init('[data-fixed]')
  Freeze.init('[data-freeze]')
  Increment.init('[data-increment]')
  Password.init('[data-password]')
  Proxy.init('[data-proxy]')
  Save.init('[data-save]')
  Reviews.init('.reviews')
  // SmoothScroll.init('[data-scroll]')
  Switch.init('[data-switch]')
  // Locrating.init('[data-locrate]')
  Print.init('[data-print]')
  AutoComplete.init('[data-autocomplete]')
  BranchFinder.init('[data-branch-finder]')
  Date.init('[data-date]')
  Fetch.init('[data-fetch]')
  Search.init('[data-search]')
  Submit.init('[data-submit]')
  Tabs.init('[data-tab]')
  TextToggle.init('[data-text]')
  Validation.init('[data-validate]')
})

//Window resize
$(window).on('resize', function() {
  PropertySearch.init('.property-search')
})
