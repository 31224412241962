const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    header: '#header',
  },

  init: function(trigger) {
    self.events.scroll(trigger)
  },

  events: {
    scroll: function(trigger) {
      // If [data-fixed] exists on page..
      if($(trigger).length) {

        console.log(trigger)
        //gets position of [data-fixed] element
        var element_top = $(trigger).offset().top

        $(window).on('scroll', function() {
          //gets current scroll position on page.
          var position = $(window).scrollTop();

          //if scroll position reaches element...
          if(position > element_top) {
            self.service.addClass(trigger);
          } else {
            self.service.removeClass(trigger);
          }
        });
      }
    }
  },

  service: {
    addClass: function (target) {
      $(target).addClass('sticky-scroll')
      $(target).css('top', $(self.props.header).height()+'px')
    },

    removeClass: function (target) {
      $(target).removeClass('sticky-scroll')
    }
  }
}
