const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(el) {
    self.service.init(el)
  },

  service: {
    init: function(el) {
      var iframe = $(el).find('iframe')
      var frame = {
        'id': $(iframe).attr('id'),
        'lat': $(iframe).data('lat'),
        'lng': $(iframe).data('lng'),
        'icon': $(iframe).data('ico')
      }

      switch ($(iframe).data('type')) {
        case 'schools-list':
          frame.type = 'schoolslist';
          // frame.maxschools= '5';
          break;
        case 'stations-map':
          frame.type = 'localinfo';
          frame.showstations = 'only';
          frame.zoom = '12';
          break;
        case 'stations-list':
          frame.type = 'stations';
          // frame.maxstations= '5';
          break;
        case 'localinfo-map':
          frame.type = 'localinfo';
          break;
        case 'localinfo-list':
          // frame.type = '';
          break;
        default:
          break;
      }

      try {
        setLocratingIFrameProperties(frame);
      }

      catch (err) {};
    }
  }
}
