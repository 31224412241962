const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    selector: '[data-password]',
    trigger: '[data-password-trigger]'
  },

  init: function(field) {
    self.events.click(self.props.trigger)
    self.events.blur(field)

    $(self.props.trigger).each(function() {
      self.service.opacity(this, .5)
    })
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(e) {
        var field = $(this).parent().find(self.props.selector)

        if ($(field).attr('type') == 'password') {
          self.service.show(field)
          self.service.opacity(trigger, 1)
        } else {
          self.service.hide(field)
          self.service.opacity(trigger, .5)
        }
      });
    },

    blur: function(field) {
      $(document).on('blur', field, function(e) {
        var trigger = $(this).parent().find(self.props.trigger)
        self.service.hide(this)
        self.service.opacity(trigger, .5)
      });
    }
  },

  service: {
    opacity: function (trigger, opacity) {
      $(trigger).css('opacity', opacity)
    },

    show: function (field) {
      $(field).attr('type', 'text')
    },

    hide: function (field) {
      $(field).attr('type', 'password')
    }
  }
}
