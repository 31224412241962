const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    country: 'GB',
    types: ['geocode', 'establishment']
  },

  init: function(selector) {
    $(selector).each(function() {
      self.events.init(this)
    })
  },

  events: {
    init: function(field) {
      self.service.initField(field)
    }
  },

  service: {
    initField: function(field) {
      var id = $(field).attr('id');
      var types = (typeof $(field).data('types') === 'undefined') ? self.props.types : $(field).data('types')
      var input = document.getElementById(id)
      var options = {
        types: types,
        componentRestrictions: {
          country: self.props.country
        }
      }

      var autocomplete = new google.maps.places.Autocomplete(input, options)

      google.maps.event.addListener(autocomplete, 'place_changed', function() {
        $(input).change()
      })

      $(field).removeAttr('autocomplete')
    }
  }
}
