const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        event.preventDefault()
        self.service.print()
      })
    }
  },

  service: {
    print: function() {
      window.print()
    }
  }
}
