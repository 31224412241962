const $ = jQuery.noConflict()
var Cookie = require('../utility/cookie.js')

var self = module.exports = {
  props: {
    action: 'search',
    method: 'GET',
    url: ajax.ajaxurl,
    dataType: 'json',
    contentType: false,
    processData: false,
    cookie: 'branch'
  },

  init: function(selector) {
    self.events.change(selector)
    self.events.choose('[data-id]')
  },

  events: {
    change: function(field) {
      $(document).on('change', field, function() {
        var location = $(this).val()
        var radius = 600
        var per = 3
        var sort = 'distance'

        if (location === '') {
          radius = 999999
          per = -1
          sort = 'title-asc'
        }

        var target = $(this).data('target')
        var data = {
          action: self.props.action,
          'type': 'branch',
          'view': 'option',
          'per-page': per,
          'sort': sort,
          'location': location,
          'radius': radius
        }

        $(target).removeClass('loaded')
        $(target).html('')

        data = $.param(data)
        self.service.post(this, data)
      })
    },

    choose: function(field) {
      $(document).on('change', field, function() {
        var id = $(this).data('id')

        if ($(this).prop('checked')) {
          Cookie.store(self.props.cookie, id)
        }
      })
    }
  },

  service: {
    post: function(field, data) {
      $.ajax({
        method: self.props.method,
        url: self.props.url,
        data: data,
        dataType: self.props.dataType,
        contentType: self.props.contentType,
        processData: self.props.processData,
        success: function(response) {
          self.service.show(response, field)
        },
        error: function(data) {
          console.log(data)
        }
      })
    },

    show: function(response, field) {
      var target = $(field).data('target')
      $(target).addClass('loaded')
      $(target).html(response.html)
      $(target).trigger('change')
    }
  }
}
