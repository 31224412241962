const $ = jQuery.noConflict()
var Currency = require('./currency.js')

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        var target = $(this).closest('.form-input-wrapper').find('input')
        var increment = $(this).data('increment')

        self.service.increment(target, increment)
      })
    }
  },

  service: {
    increment: function(target, increment) {
      var input = $(target).val()
      var increments = $(target).siblings('[data-increment]')
      var decimals = typeof $(this).data('decimals') !== 'undefined' ? $(this).data('decimals') : 0
      var min = $(target).attr('min') ? $(target).attr('min') : false
      var max = $(target).attr('max') ? $(target).attr('max') : false
      var currency = $(target).data('currency')
      var output = null
      var valid = true

      if (typeof currency !== undefined ) {
        input = Currency.props.currency.from(input)
      }

      input = parseFloat(input)
      increment = parseFloat(increment)
      output = parseFloat(input + increment)

      if (min !== false && output < min) {
        valid = false
      }

      if (max !== false && output > max) {
        valid = false
      }

      if (valid === true) {
        var newInc = 0

        if (output >= 1000000) {
          newInc = 100000
        } else if (output >= 500000) {
          newInc = 50000
        } else if (output >= 200000) {
          newInc = 20000
        } else if (output >= 100000) {
          newInc = 10000
        } else if (output >= 10000) {
          newInc = 1000
        } else if (output >= 500) {
          newInc = 100
        } else if (output >= 100) {
          newInc = 50
        } else {
          newInc = 10
        }

        $(increments).each(function() {
          var incVal = $(this).data('increment')
          incVal = parseFloat(incVal)

          if (incVal < 0) {
            incVal = newInc * -1
          } else {
            incVal = newInc
          }

          $(this).attr('data-increment', incVal)
          $(this).data('increment', incVal)
        })

        if (decimals > 0) {
          output = parseFloat(output.toFixed(decimals))
        }

        if (typeof currency !== undefined ) {
          output = Currency.props.currency.to(output)
        }

        $(target).val(output)
      }
    }
  }
}
