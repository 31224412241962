const $ = jQuery.noConflict()

$("#scrollContainer .scrollContainer__scroll-item").on("click", function() {
  $("#scrollContainer .scrollContainer__scroll-item").removeClass("active");
  $(this).addClass("active");
  // CALL scrollCenter PLUSGIN
  $("#scrollContainer").scrollCenter(".active", 300);
});


$("#process-step-scroll .scrollContainer__scroll-item ").on("click", function() {
  $("#process-step-scroll .scrollContainer__scroll-item").removeClass("active");
  $(this).addClass("active");
  // CALL scrollCenter PLUSGIN
  $("#process-step-scroll").scrollCenter(".active", 300);
});

$("#statement-scroll .scrollContainer__scroll-item ").on("click", function() {
  $("#statement-scroll .scrollContainer__scroll-item").removeClass("active");
  $(this).addClass("active");
  // CALL scrollCenter PLUSGIN
  $("#statement-scroll").scrollCenter(".active", 300);
});

  jQuery.fn.scrollCenter = function(elem, speed) {

    // this = #timepicker
    // elem = .active

    var active = jQuery(this).find(elem); // find the active element
    //var activeWidth = active.width(); // get active width
    var activeWidth = active.width() / 2; // get active width center

    //alert(activeWidth)

    //var pos = jQuery('#timepicker .active').position().left; //get left position of active li
    // var pos = jQuery(elem).position().left; //get left position of active li
    //var pos = jQuery(this).find(elem).position().left; //get left position of active li
    var pos = active.position().left + activeWidth; //get left position of active li + center position
    var elpos = jQuery(this).scrollLeft(); // get current scroll position
    var elW = jQuery(this).width(); //get div width
    //var divwidth = jQuery(elem).width(); //get div width
    pos = pos + elpos - elW / 2; // for center position if you want adjust then change this

    jQuery(this).animate({
      scrollLeft: pos
    }, speed == undefined ? 1000 : speed);
    return this;
  };

  // http://podzic.com/wp-content/plugins/podzic/include/js/podzic.js
  jQuery.fn.scrollCenterORI = function(elem, speed) {
    jQuery(this).animate({
      scrollLeft: jQuery(this).scrollLeft() - jQuery(this).offset().left + jQuery(elem).offset().left
    }, speed == undefined ? 1000 : speed);
    return this;
  };





var ps;
var ps2;
if (jQuery('#scrollContainer').length > 0) { 
   //new PerfectScrollbar('#scrollContainer');
   ps = new PerfectScrollbar('#scrollContainer',{
    useBothWheelAxes: false,
    suppressScrollY: true
   });
}

if (jQuery('#process-step-scroll').length > 0) { 
   //new PerfectScrollbar('#scrollContainer');
   ps2 = new PerfectScrollbar('#process-step-scroll',{
    useBothWheelAxes: false,
    suppressScrollY: true
   });
}



/*$(window).on('resize', function() {
  $(".scrollContainer").animate({ scrollLeft: 0 }, "slow");
})*/

setInterval(function(){ 

  if (jQuery('#scrollContainer').length > 0) { 
       ps.update();
       //ps = new PerfectScrollbar("#scrollContainer");
  }
  if (jQuery('#process-step-scroll').length > 0) {
      ps2.update(); 
      //ps2 = new PerfectScrollbar("#process-step-scroll");
  }
}, 1000);





/*scroll to drag*/

if($('#scrollContainer').length){
  /*process step*/
  const slider = document.querySelector('#scrollContainer');
  let isDown = false;
  let startX;
  let scrollLeft;

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    //console.log(walk);
  });
}


if($('#process-step-scroll').length){
/*testimonial*/
const slider2 = document.querySelector('#process-step-scroll');
let isDown = false;
let startX;
let scrollLeft;

slider2.addEventListener('mousedown', (e) => {
  isDown = true;
  slider2.classList.add('active');
  startX = e.pageX - slider2.offsetLeft;
  scrollLeft = slider2.scrollLeft;
});
slider2.addEventListener('mouseleave', () => {
  isDown = false;
  slider2.classList.remove('active');
});
slider2.addEventListener('mouseup', () => {
  isDown = false;
  slider2.classList.remove('active');
});
slider2.addEventListener('mousemove', (e) => {
  if(!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider2.offsetLeft;
  const walk = (x - startX) * 3; //scroll-fast
  slider2.scrollLeft = scrollLeft - walk;
  //console.log(walk);
});

}


