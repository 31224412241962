const $ = jQuery.noConflict()
var SLIDER = require('./slider.slick.js')

var self = module.exports = {
  props: {
    more: '.more',
    method: 'GET',
    url: ajax.ajaxurl,
    dataType: 'json',
    contentType: false,
    processData: false
  },

  init: function(selector) {
    $(selector).each(function() {
      self.service.init(this)
    })

    self.events.click(self.props.more)
  },

  events: {
    click: function(selector) {
      $(document).on('click', selector, function() {
        var target = $(this).data('proxy')

        self.service.submit(target)
      })
    }
  },

  service: {
    init: function(selector) {
      var target = $(selector)

      self.service.submit(target)
    },

    submit: function(target) {
      var data = {
        action: 'reviews'
      }

      if (typeof $(target).data('view') !== 'undefined') {
        data.view = $(target).data('view')
      } else {
        data.view = 'list'
      }

      if (typeof $(target).data('location') !== 'undefined') {
        data.location = $(target).data('location')
      }

      data = $.param(data)
      self.service.post(data, target)
    },

    post: function(data, target) {
      $.ajax({
        method: self.props.method,
        url: self.props.url,
        data: data,
        dataType: self.props.dataType,
        contentType: self.props.contentType,
        processData: self.props.processData,
        success: function(response) {
          self.service.show(response, target)
        },
        error: function(xhr, status, error) {
          console.log('Error', self.props.url, self.props.dataType, xhr.status + ': ' + xhr.statusText, error)
        }
      })
    },

    show: function(response, target) {
      $(target).html(response.html)

      if (response.view === 'slider') {
        var slider = $(target).find('.slick')

        SLIDER.service.init(slider)
      }
    }
  }
}
