const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    initiated: false,
  },

  init: function (args) {
    if (!self.service.hasRaf()) {
      return
    }

    self.customTriggers = $('[data-trigger]')
    self.triggers = $('.arrive:not(.arrive--delayed):not(.arrived):not([data-target])')

    self.loop()
    self.props.initiated = true
  },

  loop: function() {
    self.service.sweep(self.triggers,self.customTriggers)

    window.requestAnimationFrame(function() {
      self.loop(self.triggers,self.customTriggers)
    })
  },

  service: {

    /**
    * Check for ARF
    * @return {Boolean} Returns true if the user is on a mobile.
    */
    hasRaf: function() {
      if (typeof Modernizr !== 'undefined') {
        if (!Modernizr.requestanimationframe) {
          $('body').addClass('arrive--disable')
          return false
        }
        else {
          return true
        }
      }
    },

    /**
    * Force an arrival.
    * @param  {$Object} arrival The arrival element(s)
    * @return {null}
    */
    forceArrival: function(arrival) {
      if (typeof arrival == 'undefined' || !arrival) {
        arrival = $('.arrive')
      }

      arrival.each(function() {
        var $this = $(this)
        $this.addClass('arrived')
      })
    },

    /**
    * Reset arrivals
    * @param {Function} callback [description]
    */
    reset: function(arrival, callback) {
      if (typeof arrival == 'undefined' || !arrival) {
        arrival = $('.arrived:not(.arrive--ignore-reset)')
      }

      arrival.removeClass('arrived')

      if (typeof callback !== 'undefined') {
        callback()
      }
    },

    atTriggerPosition: function(el) {
      var $this = el
      var $window = $(window)
      var view_top = $window.scrollTop()
      var view_bottom = view_top + $window.height() - 100
      var trigger_position = $this.offset().top
      var offset = $this.attr('data-offset')

      if (typeof offset !== 'undefined') {
        trigger_position = trigger_position - offset
      }

      if (trigger_position <= view_bottom) {
        return true
      }
      else {
        return false
      }
    },

    sweep: function($triggers,$custom_triggers) {
      $triggers.not('.arrived').not('[data-target]').not('.arrive--delayed').each(function() {
        var $this = $(this)

        if (self.service.atTriggerPosition($this)) {
          var delay = $this.data('delay') ? $this.data('delay') : 0

          $this.addClass('arrive--delayed')

          window.requestTimeout(function() {
            $this.removeClass('arrive--delayed')
            $this.addClass('arrived')
          }, delay)
        }
      })

      if ($custom_triggers.length) {
        $custom_triggers.not('.arrived').not('.arrive--delayed').each(function() {
          var $this = $(this)

          if(self.service.atTriggerPosition($this)) {

            var delay = $this.data('delay') ? $this.data('delay') : 0

            $this.addClass('arrive--delayed')

            window.requestTimeout(function() {
              $this.removeClass('arrive--delayed')
              $this.addClass('arrived')

              var target = $this.data('trigger')
              target = $('[data-target="' + target + '"]')

              target.not('.arrived').each(function() {

                var $this = $(this)
                var target_delay = $this.data('delay') ? $this.data('delay') : 0
                $this.addClass('arrive--delayed')

                window.requestTimeout(function() {
                  $this.removeClass('arrive--delayed')
                  $this.addClass('arrived')
                },target_delay)

              })

            }, delay)
          }
        })
      }
    }
  }
}
