const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(selector) {
    var items = $(selector)

    self.service.apply(items)
    self.events.resize(items)
  },

  events: {
    resize: function(items) {
      $(window).on('resize', function() {
        self.service.apply(items)
      })
    }
  },

  service: {
    apply: function(items) {
      var tallest = 0
      var row = 0
      var divs = 0
      var rowDivs = []
      var top = 0

      $(items).each(function() {
        $(this).outerHeight('auto')
        top = $(this).offset().top

        if (row != top) {
          for (divs = 0; divs < rowDivs.length; divs++) {
            rowDivs[divs].outerHeight(tallest)
          }

          rowDivs.length = 0 // empty the array
          row = top
          tallest = $(this).outerHeight()
          rowDivs.push($(this))
        } else {
          rowDivs.push($(this))
          tallest = (tallest < $(this).outerHeight()) ? ($(this).outerHeight()) : (tallest)
        }

        for (divs = 0; divs < rowDivs.length; divs++) {
          rowDivs[divs].outerHeight(tallest)
        }
      })
    }
  }
}
