const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    widths: [0, 575, 767, 1023, 1279, 1599]
  },

  init: function(selector) {
    $(selector).each(function() {
      self.service.init(this)
    })

    self.events.resize()
  },

  events: {
    resize: function() {
      $(window).on('resize', function() {
        self.service.heightReset()
      })
    }
  },

  service: {
    init: function(slider) {
      var settings = {
        infinite: true,
        pauseOnHover: false,
        swipeToSlide: true,
        touchMove: true,
        swipe: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick__dots',
        nextArrow: '<button type="button" class="slick__next"><i class="fa fa-angle-right"></i></button>',
        prevArrow: '<button type="button" class="slick__prev"><i class="fa fa-angle-left"></i></button>',
        mobileFirst: true
      };

      var data = Object.entries($(slider).data())

      for (var i = 0; i < data.length; i++) {
        data[i] = self.service.processData(data[i][0], data[i][1])
      }

      for (var i = 0; i < data.length; i++) {
        settings[data[i][0]] = data[i][1]
      }

      $(slider).on('init', function (event, slick, currentSlide) {
        var slide = $(slick.$slides.get(currentSlide))
        self.service.playVideo($(slide))
      })

      $(slider).on('afterChange', function (event, slick, currentSlide) {
        var slide = $(slick.$slides.get(currentSlide))
        self.service.playVideo($(slide))
      })

      $(slider).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var slide = $(slick.$slides.get(currentSlide))
        var next = $(slick.$slides.get(nextSlide))
        var counter = $(slider).data('counter')
        self.service.stopVideo($(slide))

        if (counter) {
          var count = $(next).data('count')
          $(counter).text(count)
        }
      })

      $(slider).slick(settings)

      if (settings.autodelay) {
        setTimeout(function () {
          $(slider).slick('slickPlay');
        }, settings.autodelay);
      }
    },

    playVideo: function(slide) {
      var video = $(slide).find('video')

      if (video.length) {
        $(video).get(0).play()
      }
    },

    stopVideo: function(slide) {
      var video = $(slide).find('video')

      if (video.length) {
        $(video).get(0).currentTime = 0
        $(video).get(0).pause()
      }
    },

    processData: function(label, value) {
      switch (label) {
        case 'responsive':
          var max = self.props.widths.length
          var responsive = []

          for (var i = 0; i < max; i++) {
            var rule = {
              breakpoint: self.props.widths[i],
              settings: {
                slidesToShow: value[i]
              }
            }

        

            responsive.push(rule);

            console.log(responsive)
            
          }
          return [label, responsive]
          break;
        case 'autospeed':
          return ['autoplaySpeed', value]
          break;
        case 'nav':
          return ['asNavFor', value]
          break;
        default:
          return [label, value]
      }
    },

    heightReset: function() {
      $('.slick').find('.slick-slide').height('auto')
      $('.slick').slick('setOption', '', '', true)
    }
  }
}
