const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(form) {
    self.events.submit(form)

    $(form).each(function() {
      var calculate = $(this).data('calculator')
      var data = self.service.data(this)

      self.events.change(this)
      //self.service[calculate](this, data)
    })
  },

  events: {
    submit: function(form) {
      $(document).on('submit', form, function(event) {
        var el = event.target
        var calculate = $(el).data('calculator')
        var data = self.service.data(el)

        event.preventDefault()
        self.service[calculate](this, data)
      })
    },

    change: function(form) {
      $(form).on('change', 'input, select, textarea', function(event) {
        var form = $(this).closest('form')
        $(form).trigger('submit')
      })
    }
  },

  service: {
    beautify: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    results: function(form, results) {
      var targets = $(form).find('[data-result]')
      var i = 0

      $(targets).each(function() {
        $(this).text(self.service.beautify(results[i]))
        i++
      })
    },

    data: function(form) {
      var formArray = $(form).serializeArray()
      var data = {}

      for (var i = 0; i < formArray.length; i++){
        data[formArray[i]['name']] = isNaN(parseFloat(formArray[i]['value'])) ? formArray[i]['value'] : parseFloat(formArray[i]['value'])
      }

      return data
    },

    mortgage: function(form, data) {
      var results = []
      var monthly = 0

      data.loan = data.price-data.deposit
      data.rateCalc = (data.rate/100)/12
      data.lengthCalc = data.length * 12
      data.exponent = Math.pow((1+(data.rateCalc)), (-(data.lengthCalc)))
      monthly = Math.round((data.loan*data.rateCalc)/(1 - data.exponent))

      results.push(monthly)
      self.service.results(form, results)
    },

    yield: function(form, data) {
      var results = []
      var value = 0
      value = ((((data.rent * 12) / data.value) * 100) + 0.00001).toFixed(2)

      results.push(value)
      self.service.results(form, results)
    },

    helptobuy: function(form, data) {
      var results = []
      var loan = data.price * .2
      var mortgage = data.price - (data.deposit + loan)

      results.push(loan, mortgage)
      self.service.results(form, results)
    },

    stamp: function(form, data) {
      var results = []
      var bands = [0, 125000, 250000, 925000, 1500000]
      var rates = [0, 0.02, 0.05, 0.1, 0.12]
      var amounts = []
      var total = 0

      if (data.type == 'first' && data.price <= 500000) {
        bands = [0, 300000]
        rates = [0, 0.05]
      } else if (data.type == 'additional') {
        var modifier = 0.03 // Modified stamp duty on additional owned properties

        rates = rates.map(function(val) {
          val = val + modifier
          return val
        });

        if (data.price < 40000) {
          rates[0] = 0 // No stamp duty on additional properties under 40,000
        }
      }

      for (var i = 0; i < bands.length; i++) {
        if (data.price > bands[i]) {
          if (i === (bands.length - 1) || (data.price - bands[i+1]) < 0) {
            amounts[i] = data.price - bands[i]
          } else {
            amounts[i] = bands[i+1] - bands[i]
          }
        }
      }

      for (var i = 0; i < amounts.length; i++) {
        var total = total + (amounts[i] * rates[i])
      }

      results.push(total)
      self.service.results(form, results)
    }
  }
}
