const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(event) {
        if (typeof $(this).data('text') !== 'undefined') {
          self.service.text(this)

          if (typeof $(this).data('twin') !== 'undefined') {
            var twin = $(this).data('twin')
            self.service.text($(twin))
          }
        }
      })
    }
  },

  service: {
    text: function(trigger) {
      var original = $(trigger).html()
      var updated = $(trigger).data('text')

      $(trigger).html(updated).data('text', original)
    }
  }
}
