const $ = jQuery.noConflict()
var Collapse = require('../plugins/collapse.js')
var Fetch = require('../plugins/fetch.ajax.js')

var self = module.exports = {
  map: '',
  aciveWindow: null,

  init: function(selector) {
    $(selector).each(function() {
      self.service.initMap(this)
    })
  },

  service: {
    initMap: function(map) {
      var markers = $(map).find('.marker')
      var offset = $(map).data('offset') || 0
      var args = {
        zoom : 12,
        center : new google.maps.LatLng(0, 0),
        mapTypeId : google.maps.MapTypeId.ROADMAP,
        scrollwheel : false,
      }

      self.map = new google.maps.Map($(map)[0], args)
      self.map.markers = []

      $(markers).each(function() {
        self.service.addMarker(self.map, this)
      })

      self.service.centerMap(self.map, offset)

      google.maps.event.addDomListener(window, 'resize', function() {
        var center = self.map.getCenter();

        google.maps.event.trigger(self.map, 'resize');
        self.map.centerMap(center);
      })

      return self.map
    },

    addMarker: function(map, marker) {
      var lat = $(marker).data('lat')
      var lng = $(marker).data('lng')
      var position = new google.maps.LatLng(lat, lng)
      var icon = $(marker).data('ico')

      // Sidebar variables
      var collapse = $(marker).data('collapse');
      var id = $(marker).data('id');
      var target = $(marker).data('target');

      var args = {
        position: position,
        map: map,
        animation: google.maps.Animation.DROP
      }

      if (icon) {
        args.icon = icon
      }

      var newMarker = new google.maps.Marker(args)
      map.markers.push(newMarker)

      if ($(marker).html().length) {
        var infowindow = new google.maps.InfoWindow({
          content: $(marker).html()
        })
        

        google.maps.event.addListener(newMarker, 'click', function() {
          if (self.activeWindow) {
            self.activeWindow.close()
          }

          if(id == undefined) {
            infowindow.open(map, newMarker)
          }
        })
      }

      if (collapse) {
        google.maps.event.addListener(newMarker, 'click', function() {
          if (!$(collapse).hasClass('show')) {
            Collapse.service.toggle(collapse)
          }
        })
      }

      if (id) {
        google.maps.event.addListener(newMarker, 'click', function() {
          var data = {
            action: 'fetch',
            file: 'components/property/sidebar.php',
            args: {
              id: id
            }
          }

          data = $.param(data)
          Fetch.service.post(data, target)
        })
      }
    },

    centerMap: function(map, offset) {
      var bounds = new google.maps.LatLngBounds();

      $.each(map.markers, function(i, marker) {
        var lat = marker.position.lat()
        var lng = marker.position.lng()
        var pos = new google.maps.LatLng(lat, lng)

        bounds.extend(pos);
      });

      if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());
        map.panBy(offset, 0);
        map.setZoom(12);
      } else {
        map.fitBounds(bounds);
      }
    }
  }
}
