const $ = jQuery.noConflict()

var self = module.exports = {
  init: function (selector) {
    var nav = selector
    var trigger = '[data-nav="#' + $(nav).attr('id') + '"]'
    var parentLink = '#' + $(nav).attr('id') + ' .menu-item-has-children'

    if ($(nav).hasClass('show')) {
      $(trigger).addClass('active')
    }

    self.events.toggleClick(nav, trigger)
    self.events.parentClick(parentLink)
  },

  events: {
    toggleClick: function (nav, trigger) {
      $(document).on('click', trigger, function(event) {
        event.preventDefault()
        self.service.toggleClickHandler(nav, this)
      })
    },

    parentClick: function (trigger) {
      $(document).on('click', trigger + ' a', function(event) {
        //event.preventDefault()
        self.service.parentClickHandler(trigger)
      })
    }
  },

  service: {
    toggleClickHandler: function (nav, trigger) {
      if ($(nav).hasClass('show')) {
        $(nav).removeClass('show')
        $(trigger).removeClass('active')
      } else {
        $(nav).addClass('show')
        $(trigger).addClass('active')
      }
    },

    parentClickHandler: function (trigger) {
      if ($(trigger).hasClass('show')) {
        $(trigger).removeClass('show')
      } else {
        $(trigger).addClass('show')
      }
    }
  }
}
