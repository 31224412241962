const $ = jQuery.noConflict()

//statement modale tab
$('#statement__tabs .tabs:not(:first)').addClass('inactive');
$('.statement__img').hide();
$('.statement__img:first').show();
    
$('#statement__tabs .tabs').on('click', function(){
    var t = $(this).attr('id');
    if($(this).hasClass('inactive')){ //this is the start of our condition 
      $('#statement__tabs .tabs').addClass('inactive');           
      $(this).removeClass('inactive');
      
      $('.statement__img').hide();
      $('#'+ t + 'c').fadeIn('slow');
   }
});

$( "#statement__tabs .tabs" ).on('mouseover', function() {
   var t = $(this).attr('id');
   if($(this).hasClass('inactive')){ //this is the start of our condition 
     $('#statement__tabs .tabs').addClass('inactive');           
     $(this).removeClass('inactive');
     
     $('.statement__img').hide();
     $('#'+ t + 'c').fadeIn('slow');
  }
});


  $('.statement__tabs').on('afterChange', function(event, slick, currentSlide, nextSlide){
    var elCurrentSlide=$(slick.$slides.get(currentSlide)),
      elTabImage = elCurrentSlide.find('.tabs').attr('id');

       $('#statement__tabs .tabs').addClass('inactive');           
       $(elTabImage).removeClass('inactive');
       
       $('.statement__img').hide();
       $('#'+ elTabImage + 'c').fadeIn('slow');
  });
