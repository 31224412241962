const $ = jQuery.noConflict()
var Cookie = require('../utility/cookie.js')

var self = module.exports = {
  props: {
    separator: ','
  },

  init: function(selector) {
    self.events.click(selector)
  },

  events: {
    click: function (button) {
      $(document).on('click', button, function (e) {
        var type = $(this).data('save')
        var name = $(this).data('cookie')
        var value = $(this).data('value')

        e.preventDefault()
        self.service.process(type, name, value)

        if (typeof $(this).data('text') !== 'undefined') {
          self.service.text(this)
        }

        $(this).toggleClass('saved')
      })
    }
  },

  service: {
    process: function (type, name, value) {
      var data = Cookie.get(name)

      if (value === 'params') {
        value = window.location.search;
      }

      if (data) {
        data = data.split(self.props.separator)

        self.service[type](name, value, data)
      } else if (type == 'add' || type == 'toggle') {
        self.service.create(name, value)
      }
    },

    create: function (name, value) {
      var data = []
      data.push(value)
      Cookie.store(name, data)
    },

    toggle: function (name, value, data) {
      var index = data.indexOf(String(value))

      if (index > -1) {
        data.splice(index, 1)
      } else {
        data.push(value)
      }

      data.join(self.props.separator)
      Cookie.store(name, data)
    },

    add: function (name, value, data) {
      var index = data.indexOf(value)

      if (!(index > -1)) {
        data.push(value)
      }

      data.join(self.props.separator)
      Cookie.store(name, data)
    },

    remove: function (name, value, data) {
      var index = data.indexOf(value)

      if (index > -1) {
        data.splice(index, 1)
      }

      data.join(self.props.separator)
      Cookie.store(name, data)
    },

    text: function(trigger) {
      var textA = $(trigger).text()
      var textB = $(trigger).data('text')

      $(trigger).text(textB).data('text', textA)
    }
  }
}
