const $ = jQuery.noConflict()
var Date = require('./date.js')

var self = module.exports = {
  props: {
    delete: {
      element: '<button type="button" class="form-staged__delete text--primary" data-delete-duplicate><i class="fas fa-times"></i></button>',
      selector: '[data-delete-duplicate]'
    }
  },

  init: function(trigger) {
    self.events.click(trigger)
    self.events.delete(self.props.delete.selector)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(e) {
        var count = $(this).data('count')
        var target = $(this).data('duplicate')
        var next = count + 1

        $(this).data('count', next)
        self.service.duplicate(target, next)
      })
    },

    delete: function(trigger) {
      $(document).on('click', trigger, function(e) {
        var parent = $(this).parent();
        self.service.delete(parent)
      })
    },
  },

  service: {
    duplicate: function(target, next) {
      var parent = $(target).parent()
      var clone = $(target).clone()
      var first = 1
      $(clone).appendTo($(parent))

      var id = $(clone).attr('id')
      var inputs = $(clone).find('input')
      var labels = $(clone).find('label')
      id = id.replace(first, next)

      $(clone).attr('id', id)

      self.service.buttonAppend('#' + id);

      $(inputs).each(function() {
        var id = $(this).attr('id')
        var type = $(this).attr('type')
        var name = $(this).attr('name')
        var label = $(this).data('label')
        var date = $(this).data('date')

        id = id.replace(first, next)
        name = name.replace(first, next)

        $(this).attr('id', id)
        $(this).attr('name', name)

        if (type === 'checkbox' || type === 'radio') {
          $(this).prop('checked', false)
          $(this).attr('checked', false)
        } else {
          $(this).val('')
        }

        if (typeof label !== 'undefined') {
          var labelText = label.replace(first, next)
          $(this).attr('data-label', labelText)
        }

        if (typeof date !== 'undefined') {
          $(this).removeClass('hasDatepicker')
          $(this).datepicker(Date.props.options)
        }
      })

      $(labels).each(function() {
        var attrFor = $(this).attr('for')

        if (typeof attrFor !== 'undefined') {
          attrFor = attrFor.replace(first, next)
          $(this).attr('for', attrFor)
        }
      })
    },

    delete: function(target) {
      var prev = $(target).prev();

      if ($(target).siblings().length > 1) {
        $(prev).append(self.props.delete.element)
      } else {
        $(target).find(self.props.delete.selector).remove();
      }

      $(target).remove();
    },

    buttonAppend: function(target) {
      var prev = $(target).prev()
      var button = $(target).find(self.props.delete.selector)

      $(prev).find(self.props.delete.selector).remove();
      $(button).find(self.props.delete.selector).remove();
      $(target).append(self.props.delete.element);
    },
  }
}
