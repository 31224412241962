const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(selector) {
    var items = $(selector)

    self.service.init(items)
    self.events.resize(items)
  },

  events: {
    resize: function(items) {
      $(window).on('resize', function() {
        self.service.init(items)
      })
    }
  },

  service: {
    init: function(items) {
      var targets = []

      $(items).each(function() {
        var target = $(this).data('match-height')

        if ($.inArray(target, targets) == -1) {
          targets.push(target)
        }
      })

      for (var i = 0; i < targets.length; i++) {
        var itemx = $('[data-match-height="' + targets[i] + '"]')
        var tallest = self.service.calculate(itemx)
        self.service.apply(itemx, tallest)
      }
    },

    calculate: function(items) {
      var heights = []
      var tallest

      $(items).each(function() {
        heights.push(($(this).outerHeight()))
      })

      tallest = Math.max.apply(null, heights) //cache largest value
      return tallest
    },

    apply: function(items, tallest) {
      $(items).each(function() {
        $(this).css('height', (tallest) + 'px')
      })
    }
  }
}
