const $ = jQuery.noConflict()

var self = module.exports = {
  props: {
    currency: wNumb({
      mark: '.',
      thousand: ',',
      prefix: '£'
    })
  },

  init: function(trigger) {
    $(trigger).each(function() {
      self.service.convert(this)
    })

    self.events.input(trigger)
  },

  events: {
    input: function(trigger) {
      $(document).on('input', trigger, function(event) {
        self.service.convert(this)
      })
    }
  },

  service: {
    convert: function(field) {
      var input = $(field).val()
      var number = self.props.currency.from(input)
      var output = self.props.currency.to(number)

      if (output === false) {
        output = 0
        output = self.props.currency.to(output)
      }

      $(field).val(output)
    }
  }
}
