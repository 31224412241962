const $ = jQuery.noConflict()
var COLLAPSE = require('./collapse.js')

var self = module.exports = {
  status: false,
  options: {
    trigger: ''
  },

  init: function(selector) {
    self.options.trigger = '[data-modal], [href*="#modal-"]'

      self.events.click(selector, self.options.trigger)
 
      self.events.close(selector)
      self.service.init('#wrapper', selector)

  },

  events: {
    click: function(selector, trigger) {
      $(document).on('click', trigger, function(e) {
        var parent = $(this)
        var nav = $(parent).closest('.nav')
        var modal = ''
        var timeout = ''

        if (typeof $(parent).data('timeout') !== 'undefined') {
          var timeout = $(parent).data("timeout") * 1000
        }

        if (typeof $(parent).data('modal') !== 'undefined') {
          modal = $(parent).data('modal')
        } else {
          modal = $(parent).attr('href')
        }

        if(modal !== '') {
          if (!(self.status)) {
            self.service.open(modal, timeout)
            if(timeout) {
              setTimeout(function(){ 
                self.service.close(modal)
              }, timeout);
            }
          } else {
            self.service.close(modal)
          }
        }

        if (typeof $(parent).data('slide') !== 'undefined') {
          var slide = $(parent).data('slide')

          self.service.slide(modal, slide)
        }

        if (typeof nav !== 'undefined') {
          COLLAPSE.service.toggle(nav)
        }

        e.preventDefault()
      })
    },

    close: function(selector) {
      $(document).on('click', selector, function(e) {
        if (e.target !== e.currentTarget) return
        var modal = $(e.target)
        var id = $(modal).attr('id')

        modal = '#' + id
        self.service.close(modal)
      })
    }
  },

  service: {
    init: function(parent, selector) {
      var modals = $(parent).find(selector)

      $(modals).each(function() {
        $(selector).appendTo('body')
      })
    },

    open: function(modal, timeout) {
      self.status = true

      $(modal).trigger('opened')

      if(timeout === undefined || timeout === 'undefined' || timeout === '' ) {
        $('body').addClass('prevent-scroll')
      }

      if ($('body.ipad').length) {
        $('html').addClass('prevent-scroll')
      }

      $(modal).addClass('active')

      var modalIframes = $(modal).find('iframe');
      if(modalIframes.length) {
        $(modalIframes).each(function() {
          $(this).attr('src',$(this).attr('data-src'));
        })
      }

      var open = setTimeout(function() {
        $(modal + ' .modal__inner').addClass('active')
      }, 100)

      if (typeof ga == 'function') {
        var gaLabel = modal
        var gaEvent = 'Opened'
        var gaCategory = 'Popup'
        ga('send', 'event', gaCategory, gaEvent, gaLabel)
      }
    },

    close: function(modal) {
      $(modal).trigger('closed')
      self.status = false

      $('body').removeClass('prevent-scroll')

      if($('body.ipad').length) {
        $('html').removeClass('prevent-scroll')
      }

      $(modal + ' .modal__inner').removeClass('active')

      var open = setTimeout(function(){
        $(modal).removeClass('active')
      }, 100)

      $(document).trigger('modalclose', modal)

      var modalIframes = $(modal).find('iframe');
      if(modalIframes.length) {
        $(modalIframes).each(function() {
          $(this).attr('src','');
        })
      }
    },

    slide: function(modal, slide) {
      if ($(modal).find('.slick').length !== 0) {
        var slick = $(modal).find('.slick').first()

        $(slick).find('.slick-slide').height('auto')
        $(slick).slick('setOption', '', '', true)

        $(slick).slick('slickGoTo', slide)
      }
    }
  }
}

$('.success-close-btn').on('click', function(e) {
  $('.modal').removeClass('active');
  $('.form-staged__success').removeClass('active');
  $('.form-staged__step[data-step="1"]').addClass('active');
})


