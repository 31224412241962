const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(selector) {
    $(selector).each(function() {
      self.service.addClass($(this))
    })
  },

  service: {
    addClass: function(item) {
      $(item).parent('ul').addClass('active')
      $(item).closest('.top-list-item').addClass('active')
      $(item).closest('.top-sub-menu').addClass('active')
    }
  }
}
