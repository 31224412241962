const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(trigger) {
    self.events.click(trigger)
  },

  events: {
    click: function(trigger) {
      $(document).on('click', trigger, function(e) {
        var target = $(this).data('scroll') ? $(this).data('scroll') : $(this).attr('href')
        var offset = $(this).data('offset') ? $(this).data('offset') : $('#header').outerHeight()
        var speed = $(this).data('speed') ? $(this).data('speed') : 200

        self.service.scroll(target, offset, speed, $(this))
        e.preventDefault()
      })
    }
  },

  service: {
    scroll: function (target, offset, speed, clickedElement) {
      offset = offset || 0
      speed = speed || 200

      if(target == 'next') {
        target = $(clickedElement).closest('section').next('section');
      }

      if (target.length) {
        $('html, body').animate({
          scrollTop: $(target).offset().top - offset
        }, speed)
      }
    }
  }
}
