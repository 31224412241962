const $ = jQuery.noConflict()

var self = module.exports = {
  init: function(target) {
    self.service.init(target)
    self.events.resize(target)
  },

  events: {
    resize: function(target) {
      $(window).on('resize', function() {
        self.service.init(target)
      })
    },
  },

  service: {
    init: function(target) {
      var parent = $('body')
      var footer = $(parent).find(target)
      var height = $(footer).outerHeight(true)

      self.service.css(parent, height)
    },

    css: function(parent, height) {
      var wrapper = $(parent).find('#wrapper')
      var push = $(parent).find('#push')

      $(wrapper).css('margin-bottom', -height)
      $(push).css('height', height)
    }
  }
}


